import React from 'react'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import SaleRegisterForm from '../component/SaleRegisterForm/SaleRegisterForm'
import PlainCenterText from '../component/PlainCenterText/PlainCenterText'
import OfferCardsContainer from '../component/OfferCardsContainer/OfferCardsContainer'

function WholesaleOffers() {
  return (
    <div>
        <Helmet>
            <title> 
            Custom Engagement Rings & Lab-Grown Diamonds | Private Jeweller Services
            </title>
            <meta 
            name='description' 
            content="Shop premium-quality wholesale diamonds, lab-grown diamonds, and stunning engagement rings at unbeatable prices. Lee David Diamonds delivers the brilliance you deserve." 
            />
          <link rel="canonical" href="https://leedaviddiamonds.com/wholesale-offers" />
          <meta name="keywords" content="Lab grown diamonds, Wholesale diamonds price"/>
            </Helmet>
        
            <HeroBanner
              subHeading=""
              Heading="WHOLESALE OFFERS"
              nextLineSpanHeading=""
              Paragraph="Experience the brilliance of wholesale diamonds at Lee David Diamond, where quality meets affordability. Whether you're looking for stunning lab grown diamonds or the perfect engagement ring, we offer exceptional value at wholesale diamond prices. Let us help you find the perfect sparkler without breaking the bank!"
              bgImg="/images/creatives/wholesale-hero-bg.jpg"
              btn={false}
              sideLinks={false}
              marginBottom={false}
            />


            <OfferCardsContainer/>

            <SaleRegisterForm/>

            <div className='container pb-5 mb-4 mt-4'>
            <PlainCenterText
                bgImg='/images/creatives/wholesaleplain-text-bg.jpg'
                heading='PLEASE NOTE THAT THIS IS NOT AN ONLINE STORE.'
                paragraph='To purchase any items you see, call or text (905) 467-7766 or schedule an in-person appointment.'
                shrinkedHeading={"shrinked-heading-70"}
            />

            </div>

            <Footer
                newsletterSection={false}
            />
    </div>
  )
}

export default WholesaleOffers